import {
    styled,
    Grid,
    Box,
    Typography,
    Paper,
    Link,
  } from '@mui/material';
import "./i18n";
import { useTranslation } from "react-i18next";

const ImgLemonContacts = styled(Box)(({ theme }) => ({
    width: 'auto', 
    height: 174, 
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("Limoes-contacts.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: 20,
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
        height: 130,
    },
}));

const ContactsTitleTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 40,
    fontWeight: 100,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
        fontSize: 24,
    },
}));

const ContactsDescPhoneTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'center',
    marginTop: 0,
}));

const ContactsDescTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 22,
    fontWeight: 300,
    textAlign: 'center',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
        fontSize: 19,
    },
}));

const WaterColorPaper = styled(Paper)(({ theme }) => ({
    height: 600, 
    backgroundImage: `url("BG_Papel_Aquarela.png")`,
    padding: theme.spacing(0),
    textAlign: 'center',
    marginTop: 100,
    width: '100%', 
    zIndex: -100,
    [theme.breakpoints.down('xl')]: {
      backgroundImage: `url("BG_Papel_Aquarela-pad.png")`,
      height: 700,
      marginTop: 100,
    },
}));

const ImgContacts = styled(Box)(({ theme }) => ({
    height: 600, 
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("antares-02.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
        height: 390,
    },
}));

const ImgWidowContacts = styled(Box)(({ theme }) => ({
    width: '100%', 
    height: 165, 
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("janela.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
}));

const ContactsAddressTextLink = styled(Link)(({ theme }) => ({ 
    fontWeight: 500,
}));

function ContactsSection() {
    const { t } = useTranslation();
    return(
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            >
                <Grid item xs={12}>
                    <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{margin: '60px 0px 60px 0px'}}>
                        <Grid item xs="auto"></Grid>
                        <Grid item xs={12} sm={10} >
                            <Box>
                                <ImgContacts />
                            </Box>
                        </Grid>
                        <Grid item xs="auto"></Grid>
                        <Grid item xs={12}>
                        <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{margin: '60px 0px 60px 0px'}} id="contacts">
                            <Grid item xs={12} ><ImgWidowContacts /></Grid>
                            <Grid item xs='auto'></Grid>
                            <Grid item xs={9}><ContactsTitleTextTypography>{t("contacts.title.how.a")}</ContactsTitleTextTypography></Grid>
                            <Grid item xs='auto'></Grid>
                            <Grid item xs={12}>
                                <Box sx={{height: 50}}></Box>
                            </Grid>
                            <Grid item xs='auto'></Grid>
                            <Grid item xs={9}><ContactsDescPhoneTextTypography>{t("contacts.title.how.b")} <ContactsAddressTextLink href="https://wa.me/5548984296601" underline='none'>{t("contacts.title.how.click")}</ContactsAddressTextLink></ContactsDescPhoneTextTypography></Grid>
                            <Grid item xs='auto'></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <WaterColorPaper>
                        <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        elevation={0}
                        square={true}
                        spacing={3}>
                            <Grid item xs={12}><ImgLemonContacts /></Grid>
                            <Grid item xs={12}><ContactsTitleTextTypography>{t("contacts.title")}</ContactsTitleTextTypography></Grid>
                            <Grid item xs={0} lg={1}></Grid>
                            <Grid item xs={12} lg={5}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}>
                                        <Grid item xs={12}>
                                            <ContactsDescTextTypography>{t("contacts.description.a")}</ContactsDescTextTypography>
                                        </Grid>
                                        <Grid item xs={12}><ContactsDescPhoneTextTypography><Link href="https://wa.me/5548984296601" underline='none'>{t("contacts.phone.a")}</Link></ContactsDescPhoneTextTypography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={3}>
                                        <Grid item xs={12}>
                                            <ContactsDescTextTypography>{t("contacts.description.b")}</ContactsDescTextTypography>
                                        </Grid>
                                        <Grid item xs={12}><ContactsDescPhoneTextTypography>{t("contacts.phone.b")}</ContactsDescPhoneTextTypography></Grid>
                                </Grid>

                            </Grid>
                            
                            <Grid item xs={0} lg={1}></Grid>
                        </Grid>
                    </WaterColorPaper>
                </Grid>
            </Grid>
            </Grid>
        
    );
}
export default ContactsSection;