import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  colors,
} from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: colors.red.A400,
    },
  },
  typography: {
    fontFamily: [
      "Roboto Mono",
      "monospace"
    ].join(','),
    fontOpticalSizing: 'auto',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 770,
      md: 922,
      lg: 1504,
      xl: 1536,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
