import {
  Container,
} from '@mui/material';
import CarouselSection from './CarouselSection';
import AboutSection from './AboutSection';
import HeaderSection from './HeaderSection';
import PlaceSection from './PlaceSection';
import GiftSection from './GiftsSection';
import { useTranslation } from "react-i18next";
import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import ContactsSection from './ContactsSection';

function App() {
  const location = useLocation(); 
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('pt-BR');
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get("lang");
    if (!lang) {
      setLanguage(i18n.language);
      return;
    }
    setLanguage(lang);
   
    i18n.changeLanguage(lang);
  },[location.search, i18n]); 
  

  return (
      <Container maxWidth="false" disableGutters >
        <HeaderSection />
        <CarouselSection />
        <AboutSection />
        <PlaceSection />
        <GiftSection language={language}/>
        <ContactsSection />
      </Container>
  );
}

export default App;
