import {
    styled,
    Grid,
    Box,
    useTheme,
    useMediaQuery,
  } from '@mui/material';
import Carousel from 'react-material-ui-carousel'

const ImgCarousel = styled(Box)(({ theme, item }) => ({
    height: 720, 
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${item.url})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.between('sm','lg')]: {
        height: 600
    },
    [theme.breakpoints.down('sm')]: {
        height: 364
    },
}));

const GridCarousel = styled(Grid)(({ theme }) => ({
    margin: '0px 0px 60px 0px',
    [theme.breakpoints.down('xl')]: {
        margin: '-0px 0px 60px 0px',
    },
}));



function Item(props)
{
    return (
        <ImgCarousel item={props.item}></ImgCarousel>
    )
}



function CarouselSection(){
    var items = [
        
        {
            url: "carousel-02.png"
        },
        {
            url: "carousel-03.png"
        },
        {
            url: "carousel-04.png"
        },
        {
            url: "carousel-05.jpg"
        },
       
        {
            url: "carousel-07.jpg"
        },
        {
            url: "carousel-08.jpg"
        }
    ]

    const theme = useTheme();
    const greatherThanMedium = useMediaQuery(theme.breakpoints.up("md"));
    return (
        <GridCarousel
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        p={2}>
          <Grid item xs={12} sm={10}>
            <Box>
              <Carousel 
                autoPlay 
                animation={'slide'} 
                navButtonsAlwaysVisible={true} 
                indicators={false} 
                NextIcon={greatherThanMedium && <img alt="next arrow" src="next.png"/>} 
                PrevIcon={greatherThanMedium && <img alt="prev arrow" src="prev.png"/>}
                navButtonsProps={{          
                    style: {
                        backgroundColor: 'transparent',
                    }
                }}>
                    {
                        items.map( (item, i) => <Item key={i} item={item} /> )
                    }
                
                </Carousel>
            </Box>
          </Grid>
        </GridCarousel>
        
    )
}

export default CarouselSection;