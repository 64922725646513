import {
    styled,
    Grid,
    Box,
    Typography,
    Paper,
    Link,
} from '@mui/material';
import "./i18n";
import { useTranslation } from "react-i18next";

const ImgPlace = styled(Box)(({ theme }) => ({
    height: 600, 
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("antares-01.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
        height: 390
    },
}));

const ImgLemonPlace = styled(Box)(({ theme }) => ({
    width: 92, 
    height: 92, 
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("Limoes-place.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
        height: 53,
        width: '90%'
    },
}));

const ImgHotelPlace = styled(Box)(({ theme }) => ({
    width: 'auto', 
    height: 525, 
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("Casa-e-Aberturas-01.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: 20,
    marginTop: -100,
    [theme.breakpoints.down('xl')]: {
        marginLeft: 60,
        marginTop: -80,
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: 90,
    },
    [theme.breakpoints.down('sm')]: {
        height: 300,
        marginLeft: 50,
        marginTop: 0,
        marginBottom: 90,
    },
    
}));


const PlaceTitleTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 40,
    fontWeight: 100,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 65,
        fontSize: 24,
        textAlign: 'center',
    },
}));

const PlaceNameTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 22,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        fontSize: 19,
    },

}));

const PlaceAddressTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 16,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        fontSize: 14,
    },
}));

const PlaceAddressTextLink = styled(Link)(({ theme }) => ({ 
    fontWeight: 500,
}));

const PlaceTimeTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 22,
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        fontSize: 18,
    },
}));

const WaterColorPaper = styled(Paper)(({ theme }) => ({
    height: 1000, 
    backgroundImage: `url("BG_Papel_Aquarela.png")`,
    padding: theme.spacing(0),
    textAlign: 'center',
    marginTop: 300,
    position: 'absolute', 
    width: '100%', 
    zIndex: -100,
    [theme.breakpoints.down('xl')]: {
      backgroundImage: `url("BG_Papel_Aquarela-pad.png")`,
      height: 1000,
      marginTop: 300,
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: 250,
      }
  }));

const MainGrid = styled(Grid)(({ theme }) => ({
    margin: '60px 0px 60px 0px', 
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        margin: '40px 0px 40px 0px',
    },
  }));
const ContentGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        margin: '-60px 0px 60px 0px',
    },
  }));
const MainHowGrid = styled(Grid)(({ theme }) => ({
    margin: '120px 0px 60px 0px', 
    [theme.breakpoints.down('sm')]: {
        margin: '40px 0px 40px 0px',
    },
  }));  

  const ContentHowGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginTop: 40,
    },
  }));  
function PlaceSection(){
    const { t } = useTranslation();
    return (
            <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{margin: '60px 0px 60px 0px', position: 'relative'}}>
                <Grid item xs={12}>
                    <WaterColorPaper                         
                        elevation={0}
                        square={true}/>
                </Grid>
                <Grid item xs="auto"></Grid>
                <Grid item xs={12} sm={10} >
                    <Box>
                        <ImgPlace />
                    </Box>
                </Grid>
                
                <Grid item xs="auto"></Grid>
                <Grid item xs={9} id="place">
                    <MainGrid
                    container
                    direction="row-reverse"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0}>
                        <Grid item xs={12} sm={6} ></Grid>
                        <Grid item xs={12} sm={2} ><ImgLemonPlace /></Grid>
                        <Grid item xs='auto'></Grid>
                        <Grid item xs={10} sm={4} ><PlaceTitleTextTypography>{t("place.title")}</PlaceTitleTextTypography></Grid>
                        <Grid item xs={1} md='auto'></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={10} sm={6} ><ImgHotelPlace /></Grid>
                        <Grid item xs={12} sm={6}>
                            <ContentGrid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                            >
                                <Grid item xs={12}><PlaceNameTextTypography>Antares Club Hotel Lagoinha</PlaceNameTextTypography></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}><PlaceAddressTextTypography>Estrada Jornalista Jaime de Arruda Ramos, 1850</PlaceAddressTextTypography></Grid>
                                <Grid item xs={12}><PlaceAddressTextTypography>Praia da Lagoinha</PlaceAddressTextTypography></Grid>
                                <Grid item xs={12}><PlaceAddressTextTypography>Florianópolis - SC</PlaceAddressTextTypography></Grid>
                                <Grid item xs={12}><PlaceAddressTextTypography>CEP: 88054-750</PlaceAddressTextTypography></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}><PlaceTimeTextTypography>{t("place.time.event")}</PlaceTimeTextTypography></Grid>
                                <Grid item xs={12}><PlaceTimeTextTypography>{t("place.time.reception")}</PlaceTimeTextTypography></Grid>
                            </ContentGrid>
                        </Grid>
                        <Grid item></Grid>
                    </MainGrid>
                </Grid>
                <Grid item xs="auto"></Grid>
                <Grid item xs={9}>
                    <MainHowGrid
                    container
                    direction="row-reverse"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0}
                    sx={{margin: '120px 0px 60px 0px'}}>
                        <Grid item xs={12} sm={7} ></Grid>
                        <Grid item xs={12} sm={2} ><ImgLemonPlace /></Grid>
                        <Grid item xs={12} sm={3} ><PlaceTitleTextTypography>{t("place.how")}</PlaceTitleTextTypography></Grid>
                        <Grid item xs={12}></Grid>
                        
                    </MainHowGrid>
                    <MainHowGrid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0}
                    sx={{margin: '120px 0px 60px 0px'}}>
                        <Grid item xs={12} sm={6} >
                            <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                            >
                                <Grid item xs={9}><PlaceAddressTextTypography>{t("place.description")}</PlaceAddressTextTypography></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}><PlaceAddressTextTypography>{t("place.link")}<PlaceAddressTextLink href="https://www.google.com/maps/place/Antares+Club+Hotel+lagoinha/@-27.3900255,-48.427737,15z/data=!4m2!3m1!1s0x0:0xe9017596927b39fb?sa=X&ved=1t:2428&hl=pt-BR&ictx=111" underline="none" target='blank'>{t("place.click")}</PlaceAddressTextLink></PlaceAddressTextTypography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <ContentHowGrid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                            >
                                <Grid item xs={9}><PlaceNameTextTypography>Contatos</PlaceNameTextTypography></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}><PlaceAddressTextTypography>+55 48 3284 1226</PlaceAddressTextTypography></Grid>
                                <Grid item xs={12}><PlaceAddressTextTypography><PlaceAddressTextLink href="mailto:reservas@antareslagoinha.com" underline="none" >reservas@antareslagoinha.com</PlaceAddressTextLink></PlaceAddressTextTypography></Grid>
                            </ContentHowGrid>                       
                        </Grid>
                        
                    </MainHowGrid>
                    <Grid item xs={12}>
                        <iframe title="antareslagoinha-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14170.166720435207!2d-48.427737!3d-27.3900255!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9527438a89f4b501%3A0xe9017596927b39fb!2sAntares%20Club%20Hotel%20lagoinha!5e0!3m2!1spt-BR!2sbr!4v1720737001657!5m2!1spt-BR!2sbr" width="100%" height="600" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default PlaceSection;