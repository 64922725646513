import {
    Typography,
    styled,
    Paper,
    Link,
    Grid,
    Box,
    useTheme,
    useMediaQuery,
    Button,
    Drawer,
    List,
    ListItem,
    IconButton
  } from '@mui/material';
  import "./i18n";
  import { useTranslation } from "react-i18next";
import React from 'react';
import { useSearchParams } from 'react-router-dom';

  const HeaderMenuGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      height: 0
    },
  }));

  const HeaderLemonLeftBox = styled(Box)(({ theme }) => ({
    height: 180, 
    width: 260,
    backgroundImage: `url("Limoes-header-01.png")`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
      height: 0
    },
  }));
  
  const HeaderLemonRightBox = styled(Box)(({ theme }) => ({
    height: 180, 
    width: 260,
    backgroundImage: `url("Limoes-header-02.png")`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.between('md','lg')]: {
      display: 'none',
      height: 0
    },
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url("Limoes-header-03.png")`,
      height: 168
    },
  }));

  const HeaderMobileLemonRightBox = styled(Box)(({ theme }) => ({
    height: 180, 
    width: 260,
    backgroundImage: `url("Limoes-header-02.png")`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.between('md','lg')]: {
      display: 'none',
      height: 0
    },
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url("Limoes-header-03.png")`,
      height: 168,
      width: '100%'
    },
  }));

  const HeaderLemonCenterBox = styled(Box)(({ theme }) => ({
    height: 108, 
    width: 305,
    backgroundImage: `url("Limoes.png")`,
    backgroundRepeat: 'no-repeat',
  }));
  const HeaderTextCenteredBox = styled(Box)(({ theme }) => ({ 
    textTransform: 'uppercase',
    padding: theme.spacing(3),
  }));
  const HeaderTextCenteredTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 18,
    fontWeight: 300,
    height: 130,
    [theme.breakpoints.down('lg')]: {
      height: 'auto'
    },
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      textTransform: 'uppercase',
      fontWeight: 300,
    },
  }));
  const HeaderNamesTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 74,
    fontWeight: 100,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      width: 'auto',
      textAlign: 'center'
    },
  }));
  const HeaderAndTextTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 50,
    fontWeight: 100,
    paddingLeft: 75,
    paddingRight: 75,
    paddingBottom: 8,
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
      width: 'auto',
      textAlign: 'center'
    },
  }));
  const HeaderSubtitleTextCenteredTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 16,
    fontWeight: 300,
    textTransform: 'uppercase',
    width: 327,
    textAlign: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      textAlign: 'center',
      width: 200,
      letterSpacing: '3.6px'
    },
  }));
  const HeaderDateTextCenteredTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 50,
    fontWeight: 100,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      textAlign: 'center',
      width: '100%',
      letterSpacing: '3px'
    },
  }));
  const HeaderPlaceTextCenteredTypography = styled(Typography)(({ theme }) => ({ 
    fontSize: 18,
    fontWeight: 300,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      textAlign: 'center',
      width: 218,
      letterSpacing: '0px'
    },
  }));

const WaterColorPaper = styled(Paper)(({ theme }) => ({
    height: 1080, 
    width: '100%',
    zIndex: -99,
    backgroundImage: `url("BG_Papel_Aquarela.png")`,
    padding: theme.spacing(0),
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    [theme.breakpoints.down('xl')]: {
      height: 800
    },
  }));

  const WaterColorMobileMenuBox = styled(Box)(({ theme }) => ({
    backgroundImage: `url("BG_Papel_Aquarela.png")`,
    width: 250,
    height: '100%'
  }));

  const HeaderMenuMobileButton = styled(Button)(({ theme }) => ({
    height: 25, 
    width: 39,
    backgroundImage: `url("menu.png")`,
    margin: theme.spacing(3),
    backgroundRepeat: 'no-repeat',
    minHeight: 25,
    minWidth: 39,
    [theme.breakpoints.up('md')]: {
      height: 0,
      display: 'none'
    },
  }));  

  const ImgLemonHeader = styled(Box)(({ theme, item }) => ({
    height: 53, 
    width: 53,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("Limoes-circle.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: 0,
    marginRight: 10
  }));

  const ImgPlaceHeader = styled(Box)(({ theme, item }) => ({
    height: 53, 
    width: 53,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("Casa-e-Aberturas-01.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: 0,
    marginRight: 10
  }));

  const ImgWindowHeader = styled(Box)(({ theme, item }) => ({
    height: 53, 
    width: 53,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("janela.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: 0,
    marginRight: 10
  }));

  const ImgLemonsHeader = styled(Box)(({ theme, item }) => ({
    height: 53, 
    width: 53,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("Limoes-about.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: 0,
    marginRight: 10
  }));
  const HeaderPtIconButton = styled(IconButton)(({ theme }) => ({
    height: 20, 
    width: 'auto',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      height: 40,
      paddingTop: 20,
      width: 68,
    },
  }));
  const HeaderItIconButton = styled(IconButton)(({ theme }) => ({
    height: 20, 
    width: 'auto',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      height: 40,
      paddingTop: 20,
      width: 68,
    },
  }));

  const ImgPtHeader = styled(Box)(({ theme }) => ({
    height: 20, 
    width: 20,
    backgroundImage: `url(pt.png)`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      height: 20,
      width: 30,
    },
  }));

  const ImgItHeader = styled(Box)(({ theme }) => ({
    height: 20, 
    width: 20,
    backgroundImage: `url(it.png)`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('md')]: {
      height: 20,
      width: 30,
    },
  }));


  function MenuHeader() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

    const { t } = useTranslation();
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const lessThanMedium = useMediaQuery(theme.breakpoints.down("md"));
    return(
      <>
        {
          lessThanMedium ? 
          <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="flex-start"
          gap={0}>
            <Grid item xs={7}>
              <HeaderMenuMobileButton onClick={toggleDrawer(true)}></HeaderMenuMobileButton>
              <Drawer open={open} onClose={toggleDrawer(false)} >
                <WaterColorMobileMenuBox role="presentation" onClick={toggleDrawer(false)}>
                  <List>
                    <ListItem>
                      <ImgLemonHeader /><Link href="#about" underline="none" ><HeaderTextCenteredTypography>{t("about")}</HeaderTextCenteredTypography></Link>
                    </ListItem>
                    <ListItem>
                      <ImgPlaceHeader /><Link href="#place"  underline="none"><HeaderTextCenteredTypography>{t("place")}</HeaderTextCenteredTypography></Link>
                    </ListItem>
                    <ListItem>
                      <ImgLemonsHeader /><Link href="#gifts"  underline="none"><HeaderTextCenteredTypography>{t("gifts")}</HeaderTextCenteredTypography></Link>
                    </ListItem>
                    <ListItem>
                    <ImgWindowHeader />  <Link href="#contacts"  underline="none"><HeaderTextCenteredTypography>{t("contacts")}</HeaderTextCenteredTypography></Link>
                    </ListItem>
                    <ListItem>
                      <HeaderPtIconButton disableFocusRipple disableRipple disableTouchRipple onClick={()=>{
                         searchParams.set('lang', 'pt');
                         setSearchParams(searchParams)
                      }}>
                        <ImgPtHeader />  <HeaderTextCenteredTypography>PT</HeaderTextCenteredTypography>
                      </HeaderPtIconButton>
                      
                      <HeaderItIconButton disableFocusRipple disableRipple disableTouchRipple onClick={()=>{
                         searchParams.set('lang', 'it');
                         setSearchParams(searchParams)
                      }}>
                        <ImgItHeader /> <HeaderTextCenteredTypography>IT</HeaderTextCenteredTypography>
                      </HeaderItIconButton>
                      
                    </ListItem>
                  </List>
                </WaterColorMobileMenuBox>
              </Drawer>
            </Grid>
            <Grid item xs={5}>
            <HeaderMobileLemonRightBox></HeaderMobileLemonRightBox>
            </Grid>
          </Grid> 
          :
          <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center">
          <Grid item>
            <HeaderLemonLeftBox></HeaderLemonLeftBox>
          </Grid>
    
          <Grid item>
                <HeaderMenuGrid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={4}>
                <Grid item>
                  <HeaderTextCenteredBox>
                        <Link href="#about" underline="none" ><HeaderTextCenteredTypography>{t("about")}</HeaderTextCenteredTypography></Link>
                  </HeaderTextCenteredBox>
                </Grid>
                <Grid item>              
                      <HeaderTextCenteredTypography>-</HeaderTextCenteredTypography>
                </Grid>
                <Grid item>
                  <HeaderTextCenteredBox>
                    <Link href="#place"  underline="none"><HeaderTextCenteredTypography>{t("place")}</HeaderTextCenteredTypography></Link>
                  </HeaderTextCenteredBox>
                </Grid>
                <Grid item>              
                      <HeaderTextCenteredTypography>-</HeaderTextCenteredTypography>
                </Grid>
                <Grid item>
                  <HeaderTextCenteredBox>
                    <Link href="#gifts"  underline="none"><HeaderTextCenteredTypography>{t("gifts")}</HeaderTextCenteredTypography></Link>
                  </HeaderTextCenteredBox>
                </Grid>
                <Grid item>              
                      <HeaderTextCenteredTypography>-</HeaderTextCenteredTypography>
                </Grid>
                <Grid item>
                  <HeaderTextCenteredBox>
                    <Link href="#contacts"  underline="none"><HeaderTextCenteredTypography>{t("contacts")}</HeaderTextCenteredTypography></Link>
                  </HeaderTextCenteredBox>
                </Grid> 
                <Grid item>
                  <HeaderTextCenteredBox>
                    <HeaderTextCenteredTypography>
                      <HeaderPtIconButton aria-label="pt" disableFocusRipple disableRipple disableTouchRipple onClick={()=>{
                         searchParams.set('lang', 'pt');
                         setSearchParams(searchParams)
                      }}>
                        <ImgPtHeader /> 
                      </HeaderPtIconButton>
                      <HeaderItIconButton aria-label="it" disableFocusRipple disableRipple disableTouchRipple onClick={()=>{
                         searchParams.set('lang', 'it');
                         setSearchParams(searchParams)
                      }}>
                        <ImgItHeader />
                      </HeaderItIconButton>
                    </HeaderTextCenteredTypography>
                  </HeaderTextCenteredBox>
                </Grid>
              </HeaderMenuGrid> 
          </Grid>
          <Grid item>
            <HeaderLemonRightBox></HeaderLemonRightBox>
          </Grid>
        </Grid>
        }
      </>

      
    );
  }
  
  function TitleHeader() {
    return(
      <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end">
              <Grid item xs={12} sm='auto'>
                <Box >
                  <HeaderNamesTextTypography>BRUNA</HeaderNamesTextTypography>
                </Box>
              </Grid>
              <Grid item xs={12} sm='auto'>
                <Box >
                  <HeaderAndTextTypography>&</HeaderAndTextTypography>
                </Box>
              </Grid>
              <Grid item xs={12} sm='auto'>
                <Box>
                  <HeaderNamesTextTypography>SILVIO</HeaderNamesTextTypography>
                </Box>
              </Grid>
      </Grid>
    );
  }
  
  function ImgHeader() {
    
    return(
      <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
              <Grid item>
                <HeaderLemonCenterBox></HeaderLemonCenterBox>
              </Grid>
      </Grid>
    );
  }
  
  function SubtitleHeader() {
    const { t } = useTranslation();
    return(
      <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
              <Grid item xs='auto'>
                <HeaderSubtitleTextCenteredTypography>{t("subtitle")}</HeaderSubtitleTextCenteredTypography>
                
              </Grid>
      </Grid>
    );
  }
  
  function DateHeader() {
    return(
      <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
              <Grid item>
                <Box 
                display="flex"
                alignItems="center"
                p={0}>
                  <HeaderDateTextCenteredTypography>31 . 05 . 2025</HeaderDateTextCenteredTypography>
                </Box>
              </Grid>
      </Grid>
    );
  }
  
  function PlaceHeader() {
    return(
      <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end">
              <Grid item>
                  <HeaderPlaceTextCenteredTypography>Antares Lagoinha - Florianópolis - Brasil</HeaderPlaceTextCenteredTypography>
              </Grid>
      </Grid>
    );
  }

function HeaderSection() {
    return (
      <>
            <WaterColorPaper                     
            elevation={0}
            square={true} / >
            <MenuHeader />
            <TitleHeader />
            <ImgHeader />
            <SubtitleHeader />
            <DateHeader />
            <PlaceHeader />
      </>
    );
  }


export default HeaderSection;